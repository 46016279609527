@font-face {
    font-family: 'the_missus_handbold';
    src: url('fonts/the_missus_hand_strong-webfont.woff2') format('woff2'),
    url('fonts/the_missus_hand_strong-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'the_missus_handnormal';
    src: url('fonts/the_missus_hand_normal-webfont.woff2') format('woff2'),
    url('fonts/the_missus_hand_normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'hun-din_1451regular';
    src: url('fonts/hundin1451-webfont.woff2') format('woff2'),
    url('fonts/hundin1451-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.landing {
    background: #000;
    height: 100%;
    text-align: center;
    color: #FFF;

    p {
        color: #FFF;
    }

    .container-fluid {
        height: 100%;

        .row {
            height: 100%;

            @media screen and (min-width: 768px) {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                height: 100%;
            }

        }

        .column {
            position: relative;
            cursor: pointer;

            @media screen and (max-width: 767px) {
                height: 50%;
            }

            &.restaurant {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    opacity: 0.15;
                    background: url("../img/bg_line.gif") center;
                    z-index: 0;
                }

                &:after {
                    content: "";
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    background: url('../img/sfeerfoto-restaurant.jpg');
                    background-size: cover;
                    z-index: 1;
                    -webkit-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .inner {
                    position: relative;
                    z-index: 2;

                    p {
                        font-size: 21px;
                    }

                    .button {
                        font-family: 'hun-din_1451regular';
                        font-weight: normal;
                        text-transform: lowercase;
                        font-size: 22px;

                        @media screen and (max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }

            }

            &.bar {
                font-family: 'the_missus_handnormal';
                background: url('../img/landing-bg-bar.jpg') center no-repeat;
                background-size: cover;

                &:after {
                    content: "";
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    background: url('../img/sfeerfoto-bar.jpg');
                    background-size: cover;
                    z-index: 1;
                    -webkit-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .inner {
                    position: relative;
                    z-index: 2;

                    .image {

                        img {
                            width: 80%;
                            margin-left: 10%;
                        }
                    }

                    p {
                        font-size: 21px;
                    }

                    .button {
                        font-family: 'hun-din_1451regular';
                        font-weight: normal;
                        text-transform: lowercase;
                        font-size: 22px;

                        @media screen and (max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }
            }

            .inner {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                padding: 0px 20px;
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);

                .button {
                    color: #FFF;
                    border: 1px solid #FFF;

                    &:hover {
                        background: #FFF;
                        color: #000;
                    }

                    &:after {
                        border: 1px solid #FFF;
                    }
                }
            }

            .image {
                position: relative;
                width: 100px;
                margin: auto;

                @media screen and (min-width: 992px) {
                    width: 200px;
                }

                @media screen and (min-width: 1200px) {
                    width: 300px;
                }

                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                }
            }

        }

    }

}